import { useState, useEffect } from 'react';
import ServiceMap from '../service_map';


const PackageService = {
    getUrlFor(uriId, file, query){
        const queryArgs = Object.entries(query).map(([k, v]) => `${k}=${v}`).join('&');
        return `${ServiceMap.vq}/api/vq/package/${uriId}/${file}${queryArgs ? ('?'+queryArgs) : ''}`;
    },
}

export default PackageService;
