import React from 'react';
import ReactDOM from 'react-dom';
import './index.scss';
import 'font-awesome/scss/font-awesome.scss';

import Routes from './Router'
import * as serviceWorker from './serviceWorker';


import './i18n';






ReactDOM.render(<Routes />, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
