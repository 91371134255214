import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import ConfigService from '../services/ConfigService';
import LocationService from '../services/LocationService';
import PackageService from '../services/PackageService';

function PackagePage({
    match: { params: { uriId }}
}){

    const { t, i18n: { language: lng } } = useTranslation('package-page');
    const [location, setLocation] = useState();
    const config = ConfigService.useConfig((location || {}).id);

    useEffect(() => {
        if (uriId) {
            LocationService.fetchLocationFor(uriId).then(
                setLocation
            ).catch(
                () => setLocation()
            );
        }
    }, [uriId])


    // ConfigService.fetchConfigFor(host, locationID).then((json) => {
    //     localStorage.setItem("locationID", locationID);
    //     localStorage.setItem("host", host);
    //     localStorage.setItem("config", JSON.stringify(json));
    //
    //     setLocation(json["location"]);
    //     setLoading(false);
    // }).catch(() => {
    //     console.log("Error in fetch");
    //     setLoading(false);
    //     setError("Location not found");
    // });

    const css = "";

    console.log('props', uriId)
    return (<div>
        {css && <style>{css}</style>}
        <section className="hero is-fullheight vq-intro-page">
            <div className="hero-body">
                <div className="container has-text-centered">
                    <div className="columns">
                        <div className="column is-6 is-offset-3">
                            <figure className="image">
                                <img style={{height: 85}} src="/VirtualQ.svg" alt="Logo"/>
                            </figure>
                            <br />
                            {location && (<>
                                <div className="title">
                                    {location.display_name}
                                </div>
                                <br />

                                <div className="panel is-primary">
                                    <p className="panel-heading">{t('queue-registration')}</p>
                                    <a className="panel-block" href={PackageService.getUrlFor(uriId, 'client_registration_qr.png', { lng })}><i className="fa fa-qrcode" /> QR Code</a>
                                    <a className="panel-block" href={PackageService.getUrlFor(uriId, 'client_register.pdf', { lng })}><i className="fa fa-file" /> Ready-to-print Pdf</a>
                                </div>

                                <div className="panel is-primary">
                                    <p className="panel-heading">{t('queue-management')}</p>
                                    <a className="panel-block" href={PackageService.getUrlFor(uriId, 'server_login_qr.png', { lng })}><i className="fa fa-qrcode" /> QR Code</a>
                                    <a className="panel-block" href={PackageService.getUrlFor(uriId, 'server_login.pdf', { lng })}><i className="fa fa-file" /> Ready-to-print Pdf</a>
                                </div>
                            </>)}
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </div>);
}


export default PackagePage;
