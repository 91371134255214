import React from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';

import HomePage from './pages/HomePage';
import PackagePage from './pages/PackagePage';


const Routes = () => (
    <Router>
        <Switch>
            <Route path="/:uriId?" exact component={PackagePage} />
            <Route path="/" component={HomePage} />
        </Switch>
    </Router>
);

export default Routes;
