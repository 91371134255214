import { useState, useEffect } from 'react';
import ServiceMap from '../service_map';


const ConfigService = {
    async fetchConfigFor(host, branchId){
        const response = await fetch(`${ServiceMap.webapp}/api/branch/${branchId | 0}/queue/config/`);
        const config = await response.json();

        const bgsplit = (config.theme.background || '').split('\n');
        config.theme.background = bgsplit.shift();
        config.theme.css = bgsplit.join('\n');

        return config;
    },


    useConfig(host, branchId){
        const [config, setConfig] = useState();

        useEffect(() => {
            if (host && branchId) {
                ConfigService.fetchConfigFor(host, branchId).then(setConfig);
            }
        }, [host, branchId]);

        return config;
    }
}

export default ConfigService;
