import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';


import en from './locales/en.json';
import es from './locales/es.json';

const languages = ['es', 'en'];

const query = window.location.search.split('?').join('').split('&').reduce((_, i) => {
    const [key, value] = (i+'=').split('=');
    _[key] = value;
    return _;
}, {});

const lng = localStorage.getItem('language') || query.lng || 'es';
console.log(lng);

i18n.use(initReactI18next).init({
  resources: {
    en,
    es,
  },
  lng,
  fallbackLng: languages,
  interpolation: {
    escapeValue: false
  }
});

export default i18n;
