import React from 'react';

function HomePage(props){
    return (<div>
        <section className="hero is-fullheight vq-intro-page">
            <div className="hero-body">
                <div className="container has-text-centered">
                    <div className="columns">
                        <div className="column is-6 is-offset-3">
                            <figure className="image">
                                <img style={{height: 85}} src="/VirtualQ.svg" alt="Logo"/>
                            </figure>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </div>);
}


export default HomePage;
