import { useState, useEffect } from 'react';
import ServiceMap from '../service_map';


const LocationService = {
    async fetchLocationFor(uriId){
        const response = await fetch(`${ServiceMap.vq}/api/location/tag/${uriId}`);
        const location = (await response.json()).data.location;
        return location;
    },
}

export default LocationService;
